
import { Component, Vue } from 'vue-property-decorator'
import { phone } from '@/utils/validate'
import { ElForm } from 'element-ui/types/form'
import { FormInfo } from '@/types/personnel'

@Component
export default class PersonnelAdd extends Vue {
  private info: FormInfo = {
    name: '',
    mobile: '',
    role: '',
    dept: '',
    loginId: ''
  }

  private rules ={
    name: [{ required: true, message: '请输入姓名', trigger: ['blur', 'change'] }],
    dept: [{ required: true, message: '请选择所属组织', trigger: ['change'] }],
    role: [{ required: true, message: '请选择角色', trigger: ['change'] }],
    mobile: [
      { required: true, message: '请输入手机号', trigger: ['blur', 'change'] },
      { validator: phone, trigger: ['blur', 'change'] }
    ],
    loginId: [{ required: true, message: '请输入登录账号', trigger: ['blur', 'change'] }]
  }

  private deptId = ''
  private deptList = []
  private roleList = []
  private prop = {
    expandTrigger: 'hover',
    value: 'dept',
    label: 'deptName',
    children: 'childNode',
    emitPath: false
  }

  private submitShow = false
  created () {
    this.deptGet()
    this.roleListGet()
    if (this.$route.params.id) {
      this.dataGet()
    }
  }

  deptGet () {
    this.$axios.get(this.$apis.department.departmentList).then((res) => {
      this.deptList = res || []
    })
  }

  roleListGet () {
    this.$axios.get(this.$apis.common.getRoleList).then((res) => {
      this.roleList = res || []
    })
  }

  dataGet () {
    this.$axios.get(this.$apis.personnel.personnelDetail, {
      userId: this.$route.params.id
    }).then((res) => {
      this.info = {
        name: res.name || '',
        mobile: res.mobile || '',
        role: res.role || '',
        dept: res.dept || '',
        loginId: res.loginId || '',
        userId: res.userId || ''
      }
      this.deptId = res.dept
    })
  }

  deptChange () {
    console.log(this.deptId)
    this.info.dept = this.deptId ? this.deptId : ''
  }

  resetPassword () {
    this.$axios.post(this.$apis.personnel.resetPassword, {
      userId: this.$route.params.id
    }).then(() => {
      this.$message({ message: '重置成功', type: 'success' })
    }).catch((err) => {
      this.$message(err)
    })
  }

  onSubmit () {
    (this.$refs.info as ElForm).validate((valid) => {
      if (valid) {
        this.submitShow = true
        const url = this.$route.params.id
          ? this.$apis.personnel.personnelUpdate
          : this.$apis.personnel.personnelAdd
        this.$axios.post(url, this.info).then(() => {
          this.$message({ message: '保存成功', type: 'success' })
          this.$router.push({ name: 'department' })
        }).catch(() => {
          this.submitShow = false
        })
      }
    })
  }
}
